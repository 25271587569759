import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { UrlParamsService } from '../url-params/url-params.service';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private readonly siteHistory: Array<string> = [];

  private backAdditionalParams: Record<string, string> | null = {};

  constructor(
    private location: Location,
    private router: Router,
    private urlParamsService: UrlParamsService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.siteHistory.push(event.urlAfterRedirects);

        if (!this.backAdditionalParams) {
          return;
        }
        const params = this.backAdditionalParams;
        Object.keys(params).forEach(key => this.urlParamsService.setFilter(key, params[key]));
        this.backAdditionalParams = null;
      }
    });
  }

  public backWithParams(
    defaultPage?:
      | string
      | Array<string>
      | {
          page: string | Array<string>;
          extras?: NavigationExtras;
        },
    backParams?: Record<string, string>
  ): void {
    this.backAdditionalParams = backParams || null;
    if (typeof defaultPage === 'object' && 'page' in defaultPage) {
      this.back(defaultPage.page, defaultPage.extras);
    } else {
      this.back(defaultPage);
    }
  }

  public back(defaultPage?: string | Array<string>, extras?: NavigationExtras): void {
    this.siteHistory.pop();
    if (this.siteHistory.length > 0) {
      this.location.back();
    } else if (defaultPage) {
      if (!Array.isArray(defaultPage)) {
        defaultPage = [defaultPage];
      }
      this.router.navigate(defaultPage, extras).catch(console.error);
    } else {
      this.router.navigateByUrl('/').catch(console.error);
    }
  }
}
