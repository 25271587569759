import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UrlParamsService {
  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router
  ) {}

  public setFilter(name: string, filter: string | null) {
    const queryParams: { [name: string]: string | null } = {};
    queryParams[name] = filter;
    this.router
      .navigate([], {
        relativeTo: this.activateRoute,
        queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      })
      .catch(console.error);
  }

  public getFilter(name: string): Observable<string | null> {
    return this.activateRoute.queryParamMap.pipe(map(params => params.get(name)));
  }
}
